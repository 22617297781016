import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'
import Quicksand from './Quicksand-SemiBold.ttf';

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}
// Kanit
const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Quicksand', sans-serif; 
  }
  @font-face{
    font-family: 'Quicksand';
    src: url('${Quicksand}') format('truetype'); 
  }
  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background: ${({theme}) => theme.isDark ? "url('/images/bg.png')" : "url('/images/bg2.png')" } ;
    // background-size: cover;
    background-size: contain;
    
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
